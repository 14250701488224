<template>
  <div class="Detail">
    <h1 class="text-h5 grey--text text-uppercase">
      <v-btn icon class="align-btn" @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Edição de Campanha
    </h1>
    <div data-html2canvas-ignore>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-tabs v-model="tab" background-color="transparent">
              <v-tab>Campanha</v-tab>
              <v-tab>Rádios</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs-items v-model="tab" style="background-color: transparent">
              <v-tab-item>
                <v-card max-width="800" v-if="editedCampaign">
                  <v-card-title> Dados da Campanha </v-card-title>
                  <v-form ref="newCampForm" v-model="isValid" lazy-validation>
                    <v-card-text class="">
                      <v-text-field
                        v-model="editedCampaign.name"
                        :rules="[(v) => !!v || 'Digite o nome da Campanha']"
                        label="Nome"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="editedCampaign.client"
                        :rules="[(v) => !!v || 'Digite o nome do Cliente']"
                        label="Cliente"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="editedCampaign.product"
                        :rules="[(v) => !!v || 'Digite o nome do Produto']"
                        label="Produto"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="editedCampaign.type"
                        :rules="[(v) => !!v || 'Digite o tipo da Peça']"
                        label="Peça - Tipo"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="editedCampaign.format"
                        :rules="[(v) => !!v || 'Digite o formato da Peça']"
                        label="Peça - Formato"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="editedCampaign.timeFrame"
                        :rules="[
                          (v) => !!v || 'Digite o período de Veiculação',
                        ]"
                        label="Veiculação"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="editedCampaign.pi"
                        label="Veiculação"
                      ></v-text-field>
                    </v-card-text>
                  </v-form>

                  <v-card-actions class="py-6">
                    <v-spacer></v-spacer>

                    <v-btn class="mx-4" color="primary" @click="saveCampaign()">
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card max-width="1200" v-if="editedCampaign">
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="editedCampaign.radiosData"
                      :search="search"
                      :footer-props="{
                        'items-per-page-all-text': 'Todas',
                        'items-per-page-text': 'Rádios por página:',
                        'items-per-page-options': [15, 50, 100, -1],
                      }"
                      sort-by="idSecom"
                      class="elevation-1"
                      item-key="idEd"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>RÁDIOS</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Pesquisar"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <template
                        v-slot:item.actions="{ item }"
                        v-if="currentUser.role == 'admin'"
                      >
                        <v-icon small class="mr-2" @click="editRadio(item)">
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          class="mr-2"
                          @click="openPrintDialog(item)"
                        >
                          mdi-file-pdf-box
                        </v-icon>
                        <v-icon small class="mr-2" @click="deleteRadio(item)">
                          mdi-delete
                        </v-icon>
                      </template>
                      <template v-slot:no-data>
                        <p class="mt-4">
                          Nenhuma rádio encontrada! Os registros cadastrados
                          aparecerão aqui.
                        </p>
                      </template>
                      <template v-slot:no-results>
                        <p class="mt-4">
                          Nenhuma resultado encontrado para busca: {{ search }}
                        </p>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>
                    <v-btn dark color="primary" @click="inportTable()"
                      >Importar Nova Planilha</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>3</v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <v-row class="mt-2">
        <v-col> </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col> </v-col>
      </v-row>
    </div>

    <!-- Edit Radio Modal -->
    <v-dialog v-model="editRadioDialog" max-width="600px" v-if="objEditRadio">
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar Rádio</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Id. SECOM"
                  v-model="objEditRadio.idSecom"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Município"
                  hint="Digite o Município da Rádio"
                  v-model="objEditRadio.municipio"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Estado"
                  hint="UF"
                  persistent-hint
                  v-model="objEditRadio.uf"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Rádio"
                  v-model="objEditRadio.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  :items="['FM', 'AM']"
                  :value="objEditRadio.fx"
                  label="FX"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="PRX"
                  v-model="objEditRadio.prx"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Programa"
                  v-model="objEditRadio.programa"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Apresentador"
                  v-model="objEditRadio.apresentador"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  label="Início"
                  v-model="objEditRadio.inicio"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Fim"
                  v-model="objEditRadio.fim"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>* indica campos obrigatórios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editRadioDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="editRadioDialog = false">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- import table dialog -->
    <v-dialog
      v-model="importTableDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="resetImport()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Importar Planilha de Rádios</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              :loading="loading"
              :disabled="!inputFile && !importedRadiosData.length"
              @click="savaRadiosData()"
            >
              Salvar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader v-if="inputFile">
          <v-subheader>Arquivo Selecionado</v-subheader>
        </v-list>
        <v-card-text>
          <v-file-input
            v-model="inputFile"
            :rules="[
              (v) => !!v || 'Selecione uma planilha para importação!',
              (v) =>
                !v ||
                v.size < 2000000 ||
                'A planilha precisa ter menos de 2 MB!',
            ]"
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
            placeholder="Selecione uma planilha"
            prepend-icon="mdi-microsoft-excel"
            label="Importar Planilha"
            :loading="fileLoading"
            :disabled="fileLoading"
            :class="{ 'mt-8': !inputFile }"
            show-size
            @click:clear="importedRadiosData = []"
            @change="changeFileInput()"
          ></v-file-input>
        </v-card-text>
        <v-card-text>
          <v-simple-table
            v-if="importedRadiosData.length"
            fixed-header
            :dense="isMobile ? true : false"
            :height="
              isMobile
                ? importedRadiosDatalength > 10
                  ? 674
                  : '100%'
                : importedRadiosData.length > 10
                ? 530
                : '100%'
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="primary darken-2 text-left white--text">
                    ID. SECOM
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    MUNICÍPIO
                  </th>
                  <th class="primary darken-2 text-left white--text">UF</th>
                  <th class="primary darken-2 text-left white--text">RÁDIO</th>
                  <th class="primary darken-2 text-left white--text">FX</th>
                  <th class="primary darken-2 text-left white--text">PRX</th>
                  <th class="primary darken-2 text-left white--text">
                    PROGRAMA
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    APRESENTADOR
                  </th>
                  <th class="primary darken-2 text-left white--text">INÍCIO</th>
                  <th class="primary darken-2 text-left white--text">FIM</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(radio, i) in importedRadiosData"
                  :key="i"
                  :class="{ grey: i % 2, 'lighten-5': i % 2 }"
                  style="cursor: pointer"
                  @click="editRadio(radio)"
                >
                  <td class="text-left">{{ radio.idSecom }}</td>
                  <td class="text-left">{{ radio.municipio }}</td>
                  <td class="text-left">{{ radio.uf }}</td>
                  <td class="text-left">{{ radio.name }}</td>
                  <td class="text-left">{{ radio.fx }}</td>
                  <td class="text-left">{{ radio.prx }}</td>
                  <td class="text-left">{{ radio.programa }}</td>
                  <td class="text-left">{{ radio.apresentador }}</td>
                  <td class="text-left">{{ radio.inicio }}</td>
                  <td class="text-left">{{ radio.fim }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- print dialog -->
    <v-dialog
      v-if="selectedRadio"
      v-model="printDialog"
      fullscreen
      hide-
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
            icon
            dark
            class="no-print"
            @click="closePrintDialog()"
            data-html2canvas-ignore
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>RELATÓRIO ANALÍTICO</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items data-html2canvas-ignore>
            <v-btn dark text class="no-print" @click="savePDF()">
              Salvar em PDF
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container style="position: relative; max-width: 100% !important">
            <img
              src="@/assets/Logo_Rotate.png"
              alt=""
              class="img-logo-rotate"
            />
            <v-row>
              <v-col cols="12" class="pt-8">
                <p>
                  <span>Cliente:</span> <span>{{ campaign.client }}</span
                  ><br />
                  <span>Campanha:</span> <span>{{ campaign.name }}</span
                  ><br />
                  <span>Produto:</span> <span>{{ campaign.product }} | </span>
                  <span>Peça:</span>
                  <span>{{ campaign.type }} - {{ campaign.format }}"</span
                  ><br />
                  <span>Veiculação:</span> <span>{{ campaign.timeFrame }}</span>
                </p>
                <p>
                  <span class="font-weight-bold"
                    >RÁDIO COMPROVANTE CENSURA</span
                  >
                  <br />
                  <span>
                    AGÊNCIA: {{ selectedRadio.agencia }} | ID SECOM:
                    {{ selectedRadio.idSecom }}</span
                  ><br />
                  <span>
                    VEÍCULO: {{ selectedRadio.name }} | Município:
                    {{ selectedRadio.municipio }} | Programa:
                    {{ selectedRadio.programa }} | Início:
                    {{ selectedRadio.inicio }} | Término:
                    {{ selectedRadio.fim }}</span
                  >
                </p>
              </v-col>
            </v-row>

            <v-row class="my-0 py-0 font-weight-bold">
              <v-col cols="1" class="py-0">
                <p class="mb-1">Pi</p>
              </v-col>
              <v-col cols="1" class="py-0">
                <p class="mb-1">Data</p>
              </v-col>
              <v-col cols="1" class="py-0">
                <p class="mb-1">Hora</p>
              </v-col>
              <v-col cols="3" class="py-0">
                <p class="mb-1">Apresentador</p>
              </v-col>
              <v-col cols="4" class="py-0">
                <p class="mb-1">Conteúdo</p>
              </v-col>
              <v-col cols="2" class="py-0">
                <p class="mb-1">Observação</p>
              </v-col>
            </v-row>

            <div v-if="!radioCensures">
              <v-row>
                <v-col cols="12"
                  ><v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular
                ></v-col>
              </v-row>
            </div>

            <div v-else>
              <div v-for="(censure, idx) in radioCensures" :key="idx">
                <v-row class="my-0 py-0" v-if="censure.audioData">
                  <v-col
                    cols="1"
                    class="py-0 pr-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">{{ censure.piVeiculo }}</p>
                  </v-col>
                  <v-col
                    cols="1"
                    class="py-0 pr-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">
                      {{
                        censure.veiculacao
                          .toDate()
                          .toLocaleDateString('pt-BR')
                          .slice(0, 6) +
                        censure.veiculacao
                          .toDate()
                          .toLocaleDateString('pt-BR')
                          .slice(-2)
                      }}
                    </p>
                  </v-col>
                  <v-col
                    cols="1"
                    class="py-0 pr-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">
                      {{
                        censure.audioData.time ? censure.audioData.time : 'N/A'
                      }}
                    </p>
                  </v-col>
                  <v-col
                    cols="2"
                    class="py-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">
                      {{ censure.apresentador }}
                      <span v-if="censure.audioData.substituto">(SUBS.)</span>
                    </p>
                  </v-col>
                  <v-col
                    cols="4"
                    class="py-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">
                      {{ censure.conteudo ? censure.conteudo : '-' }}
                    </p>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <p
                      class="mb-0 caption"
                      v-if="censure.audioData.okContent === false"
                    >
                      {{
                        censure.audioData.contentProblem
                          ? censure.audioData.contentProblem
                          : 'N/A'
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="my-0 py-0" v-else>
                  <v-col
                    cols="1"
                    class="py-0 pr-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">{{ censure.piVeiculo }}</p>
                  </v-col>
                  <v-col
                    cols="1"
                    class="py-0 pr-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">
                      {{
                        censure.veiculacao
                          .toDate()
                          .toLocaleDateString('pt-BR')
                          .slice(0, 6) +
                        censure.veiculacao
                          .toDate()
                          .toLocaleDateString('pt-BR')
                          .slice(-2)
                      }}
                    </p>
                  </v-col>
                  <v-col
                    cols="1"
                    class="py-0 pr-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">-</p>
                  </v-col>
                  <v-col
                    cols="2"
                    class="py-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">
                      {{ censure.apresentador }}
                    </p>
                  </v-col>
                  <v-col
                    cols="4"
                    class="py-0"
                    style="border-right: 1px grey solid"
                  >
                    <p class="mb-0 caption">
                      {{ censure.conteudo ? censure.conteudo : '-' }}
                    </p>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <p class="mb-0 caption">Upload de áudio não realizado</p>
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-row>
              <v-col cols="6" class="py-0">
                <!-- <p class="caption text-center mb-1 mt-8">
                  <span>Marcelo Magalhães Hildebrand</span><br />
                  <span>AVENTAR | Diretor Executivo | 61 99261-5206</span><br />
                  <span>CPF: 611.426.701-04 | RG: 1.404.312 – SSP/DF</span>
                </p> -->
                <div class="caption text-center mt-8 mb-0">
                  Marcelo Magalhães Hildebrand
                </div>
                <div
                  class="caption text-center my-0"
                  style="font-size: 0.6rem !important; height: 15px"
                >
                  AVENTAR | Diretor Executivo | 61 99261-5206
                </div>
                <div
                  class="caption text-center mb-1 mt-0"
                  style="font-size: 0.6rem !important"
                >
                  CPF: 611.426.701-04 | RG: 1.404.312 – SSP/DF
                </div>
              </v-col>
              <v-col cols="6" class="py-0"></v-col>
            </v-row>
            <!-- <v-row>
              <v-col
                cols="6"
                class="d-flex py-0"
                style="justify-content: center"
              >
                <img
                  src="@/assets/Assinatura.png"
                  width="200"
                  alt=""
                  style="align-self: center"
                />
              </v-col>
              <v-col
                cols="6"
                class="d-flex py-0"
                style="justify-content: center"
                s
              >
                <img
                  src="@/assets/Carimbo.png"
                  width="150"
                  alt=""
                  style="align-self: end"
                />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <hr />
                <p class="text-center">
                  <span class="caption font-weight-bold"
                    >AVENTAR Produção Digital LTDA | CNPJ:
                    42.243.098/0001‐58</span
                  ><br />
                  <span class="caption"
                    >Setor Comercial Sul ‐ Quadra 06, Bloco A, Ent. 2 40, Sala
                    504 ‐ Edifíco Carioca | Asa Sul, Brasília/DF ‐ CEP:
                    70.325‐900</span
                  >
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import XLSX from 'xlsx';
  import html2pdf from 'html2pdf.js';
  import { mxSlugify } from '@/mixins/SlugifyMixin';

  export default {
    name: 'Detail',

    mixins: [mxSlugify],

    components: {
      //
    },

    mounted() {
      if (this.currentUser && this.$route.params.id)
        this.loadCampaign(this.$route.params.id);
    },

    data() {
      return {
        editedCampaign: {},
        isValid: false,
        editRadioDialog: false,
        objEditRadio: null,
        fileLoading: null,
        inputFile: null,
        importTableDialog: false,
        importedRadiosData: [],
        tab: null,
        headers: [
          {
            text: 'idSecom',
            align: 'start',
            filterable: true,
            value: 'idSecom',
          },
          { text: 'UF', filterable: true, value: 'uf' },
          { text: 'Nome', filterable: true, value: 'name' },
          { text: 'Fx', filterable: true, value: 'fx' },
          { text: 'Prx', filterable: true, value: 'prx' },
          { text: 'Programa', filterable: true, value: 'programa' },
          { text: 'Apresentador', filterable: true, value: 'apresentador' },
          { text: 'Início', filterable: true, value: 'inicio' },
          { text: 'Fim', filterable: true, value: 'fim' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        search: '',
        printDialog: false,
        selectedRadio: null,
      };
    },

    computed: {
      campaign() {
        return this.$store.getters.getCampaign;
      },
      currentUser() {
        return this.$store.getters.getUser;
      },
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      },
      loading() {
        return this.$store.getters.getLoading;
      },
      radioCensures() {
        return this.$store.getters.getCensures;
      },
    },

    watch: {
      campaign(v) {
        if (v) this.editedCampaign = { ...v };
      },
      currentUser(v) {
        if (v) this.loadCampaign(this.$route.params.id);
      },
    },

    methods: {
      savePDF() {
        const fileName =
          this.mxSlugify(this.selectedRadio.idSecom) +
          '_' +
          this.mxSlugify(this.selectedRadio.name) +
          '_' +
          this.mxSlugify(this.selectedRadio.uf) +
          '_' +
          this.mxSlugify(this.selectedRadio.municipio) +
          '_' +
          this.mxSlugify(this.selectedRadio.programa) +
          '_RELATORIO-ANALITIC.pdf';
        console.log(fileName);
        const opt = {
          filename: fileName.toUpperCase(),
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { windowWidth: 1125, windowHeight: 780 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        };
        html2pdf().set(opt).from(document.body).save();
      },
      changeFileInput() {
        const file = this.inputFile;
        if (file) {
          this.fileLoading = true;
          file
            .arrayBuffer()
            .then((res) => {
              const data = new Uint8Array(res);
              const workbook = XLSX.read(data, { type: 'array' });
              const workSheet = workbook.Sheets[workbook.SheetNames[0]];
              const jsonData = XLSX.utils.sheet_to_json(workSheet, {
                header: [
                  'idRd',
                  'agencia',
                  'idSecom',
                  'uf',
                  'municipio',
                  'name',
                  'fx',
                  'prx',
                  'programa',
                  'apresentador',
                  'inicio',
                  'fim',
                ],
                raw: true,
              });
              jsonData.shift();
              //console.log(jsonData);
              jsonData.forEach((radio) => {
                radio.inicio = this.decimalToHourMinutes(radio.inicio);
                radio.fim = this.decimalToHourMinutes(radio.fim);
                this.importedRadiosData.push(radio);
              });
              this.fileLoading = false;
            })
            .catch((err) => {
              console.error(err);
            });
        }
      },
      decimalToHourMinutes(decNum) {
        if (decNum != 17) {
          decNum = decNum * 24;
        } else {
          decNum = '00';
        }
        const strNum = decNum + '';
        if (strNum.indexOf('.') != -1) {
          const strPercent = strNum.substring(strNum.indexOf('.') + 1);
          const percent = strPercent / 100;
          const hours = strNum.substring(0, strNum.indexOf('.'));
          let strHours = hours + '';
          if (strHours.length < 2) strHours = '0' + strHours;
          const min = Math.ceil(60 * percent);
          let strMin = min + '';
          if (strMin.length > 2) strMin = strMin.substring(0, 2);
          if (strMin.length < 2) strMin = strMin + '0';
          return `${strHours}:${strMin}`;
        } else {
          let strHours = decNum + '';
          if (strHours.length < 2) strHours = '0' + strHours;
          return `${strHours}:00`;
        }
      },
      resetImport() {
        this.importTableDialog = false;
        this.importedRadiosData = [];
        this.inputFile = null;
        this.fileLoading = null;
      },
      inportTable() {
        console.log('click');
        this.importTableDialog = true;
      },
      savaRadiosData() {
        if (this.inputFile && this.importedRadiosData.length) {
          const payload = {
            campaignId: this.campaign.id,
            radiosData: this.importedRadiosData,
          };
          this.$store
            .dispatch('addRadioDataToCampaignDoc', payload)
            .then((campaignId) => {
              this.resetImport();
              this.loadCampaign(campaignId);
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          console.log('not save');
        }
      },
      loadCampaign(id) {
        this.$store.dispatch('getCampaignFromFirestore', id);
      },
      editRadio(radio) {
        // console.log(radio);
        this.objEditRadio = { ...radio };
        this.editRadioDialog = true;
      },
      openPrintDialog(radio) {
        this.printDialog = true;
        console.log(radio);
        this.selectedRadio = radio;
        const payload = {
          radioData: radio,
          campaignId: this.campaign.id,
        };
        this.$store.dispatch('getCensuresbyRadio', payload);
      },
      closePrintDialog() {
        this.printDialog = false;
        this.selectedRadio = null;
        this.$store.state.censures = null;
        // this.radioCensures = null;
      },
    },
  };
</script>

<style scoped>
  .Detail {
    padding: 20px;
  }
  .align-btn {
    transform: translateY(-2px);
  }
  .img-logo-rotate {
    height: 180px;
    position: absolute;
    right: 0;
    top: 30px;
  }
  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }
</style>
